import notfond from '../assets/images/error404.png';
import {Link} from 'react-router-dom'
function NotFound() {
  return (
    <div className='flex flex-col items-center justify-center'>
        <img src={notfond} className='w-8/12 md:w-1/3' />
        <Link className='px-4 py-3 mb-10 text-[14px] rounded-[30px] text-white bg-base-accent-1' to='/'>Back To Shop</Link>
    </div>
  )
}

export default NotFound