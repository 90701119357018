import { useState } from 'react';
import Trash from '../../assets/icons/Trash';
import { api } from '../../services/apiServices';
import getToken from '../../utils/getToken';
import PencilMd from '../../assets/icons/PencilMd';
import { useTranslation } from 'react-i18next';
import Checkmark from '../../assets/icons/Checkmark'; // Assuming you have a Checkmark icon.

const AddressItem = ({
  address,
  isSelected,
  onSelect,
  onDelete,
  disableDelete,
  showRadio,
}) => {
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
  const [editedAddress, setEditedAddress] = useState({ ...address }); // Copy the current address for editing
  const token = getToken();
  const { i18n } = useTranslation();
  const {
    streetPlaceholder,
    cityPlaceholder,
    statePlaceholder,
    postalCodePlaceholder,
    countryPlaceholder,
    saveAddressBtn,
    cancelBtn,
  } = i18n.getDataByLanguage(i18n.language);

  // Handle changes in the address form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Validate Postal Code
  const validatePostalCode = async (postalCode) => {
    try {
      const response = await api.get(`locations/${postalCode}`);
      return true; // If the postal code exists, validation is successful
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return false;
      }
    }
  };

  // Handle the save of the edited address
  const handleSaveEdit = async () => {
    const { street, city, state, postalCode, country } = editedAddress;

    // Check if any field is empty
    if (!street || !city || !state || !postalCode || !country) {
      alert('All fields must be filled before saving.');
      return;
    }

    // Validate the postal code
    const isPostalValid = await validatePostalCode(postalCode);
    if (!isPostalValid) {
      alert('Invalid postal code');
      return;
    }

    try {
      // Send PUT request to update the address
      const response = await api.put(
        `addresses/${address.id}`,
        { ...editedAddress },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // If the request was successful, update the parent component with the new address
      onSelect(response.data); // Or any other way you want to handle the updated data
      setIsEditing(false); // Close the edit form
    } catch (error) {
    }
  };

  // Handle cancelling the edit
  const handleCancelEdit = () => {
    setIsEditing(false); // Close the edit form without saving
    setEditedAddress({ ...address }); // Reset the form to original values
  };

  return (
    <div
      onClick={onSelect}
      className={`p-4  duration-300 border rounded-md flex flex-col gap-1 ${
        isSelected ? 'bg-gray-300 text-gray' : 'bg-white'
      }`}
    >
      <div className="relative flex items-center justify-end mb-2">
        <button
          disabled={disableDelete}
          onClick={onDelete}
          className="disabled:text-gray-400 text-red-500 hover:text-red-700 absolute right-0 top-0"
        >
          <Trash />
        </button>

        {/* Edit button */}
        {!isEditing && (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the onSelect when clicking the edit button
              setIsEditing(true); // Toggle to edit mode
            }}
            className="text-blue-500 hover:text-blue-700 absolute right-10 top-0"
          >
            <PencilMd />
          </button>
        )}
        
        {/* Checkmark icon for selected state */}
        {isSelected && (
          <div className="absolute -left-1 -top-1 text-white">
            <Checkmark /> {/* Checkmark Icon */}
          </div>
        )}
      </div>

      {/* Display address fields when not editing */}
      {!isEditing ? (
        <div className="text-base-accent-1 flex flex-col gap-1 break-words">
          <p>{address.street}</p>
          <p>
            {address.city}, {address.state}
          </p>
          <p>{address.postalCode}</p>
          <p>{address.country}</p>
        </div>
      ) : (
        // Form to edit address
        <div className="flex flex-col py-5 gap-2">
          <input
            type="text"
            name="street"
            value={editedAddress.street}
            onChange={handleInputChange}
            className="py-2 px-4 border text-[14px] rounded-[30px]"
            placeholder={streetPlaceholder}
          />
          <input
            type="text"
            name="city"
            value={editedAddress.city}
            onChange={handleInputChange}
            className="py-2 px-4 border text-[14px] rounded-[30px]"
            placeholder={cityPlaceholder}
          />
          <input
            type="text"
            name="state"
            value={editedAddress.state}
            onChange={handleInputChange}
            className="py-2 px-4 border text-[14px] rounded-[30px]"
            placeholder={statePlaceholder}
          />
          <input
            type="text"
            name="postalCode"
            value={editedAddress.postalCode}
            onChange={handleInputChange}
            className="py-2 px-4 border text-[14px] rounded-[30px]"
            placeholder={postalCodePlaceholder}
          />
          <input
            type="text"
            name="country"
            value={editedAddress.country}
            onChange={handleInputChange}
            className="py-2 px-4 border text-[14px] rounded-[30px]"
            placeholder={countryPlaceholder}
          />

          <div className="flex gap-2 mt-2">
            <button
              onClick={handleSaveEdit}
              className="bg-base-accent-1 text-[13px] w-fit  text-white py-2 px-4 rounded-[30px]"
            >
              {saveAddressBtn}
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-[13px] w-fit text-white py-2 px-4 rounded-[30px]"
            >
              {cancelBtn}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressItem;
